/*.bg-services {*/
/*    position: relative;*/
/*    background-image: url(../../../public/images/services_background.jpg);*/
/*    !*height: 367px;*!*/
/*    background-size: cover;*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/*}*/

.bg-services {
    background: rgba(0, 0, 0, 0.7) url(../../../public/images/services_background.jpg);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
}
