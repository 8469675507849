.input-multiple__input:focus {
    --tw-ring-color: transparent !important;
}

.pageBackground_banner, .pageBackground_bg {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #161616;
}

.pageBackground_banner {
    min-height: 240px;
}

.animate-logo{
    transition: all 0.4s ease-in-out;
}

.text-header-gradient {
    font-family: Poppins, sans-serif;
    font-weight: 200;
}

.highlighted-text {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: -12deg;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgba(0,0,0,var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(247,250,252,var(--tw-text-opacity));
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: inline-block;
}
