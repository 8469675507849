@font-face {
    font-family: "ClashGrotesk";
    src: local("ClashGrotesk"),
    url("../../fonts/ClashGrotesk/ClashGrotesk-Medium.otf") format("truetype");
}

.c-card img {
    transition: transform .3s ease-in-out;
}

.c-card img:hover {
    transform: scale(1.05)
}
