.topBanner__container {
    height: 73vh;
    position: relative;
    overflow: hidden;
    min-height: 750px;
    background: #161616;
    background-size: cover;
}

/*.topBanner__container:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    top: 0;*/
/*    left: 15%;*/
/*    background: linear-gradient(to left, rgba(255,255,255,0.50) 20%, rgba(255,255,255,0.2) 70%, rgba(255,255,255,0) 95%);*/
/*    z-index: 5;*/
/*}*/

.topBanner__container .carousel__container {
    height: 100%;
}

.topBanner__container .carousel__container div {
    height: 100%;
}

.carousel__container .slick-list, .carousel__container .slick-slider, .carousel__container .slick-track {
    position: relative;
    display: block;
}

.carousel__container .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0 !important;
}

.topBanner__container .slick-track>div .background {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}

.topBanner__container .slick-track>div .background:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            16.71deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.2) 66.73%, rgba(0,0,0,0.13) 100%);
    z-index: 1;
    transform: translate3d(0, 0, 0);
}

.topBanner__container .slick-track>div .background__inner {
    height: 100%;
    width: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 33%;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}

.carousel__container .slick-track {
    top: 0;
    left: 0;
}

.topBanner__container .carousel__container div .topBanner__content__text {
    height: auto;
    z-index: 20;
}

.topBanner__container .topBanner__content__container .button__button {
    margin-top: 24px;
    display: inline-block;
    width: auto;
}

.topBanner__content__text {
    position: absolute;
    bottom: calc(20% - 80px);
    transform: translate3d(0, -40px, 0);
    /*opacity: 0;*/
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.5s transform ease-in-out 0.25s, 0.5s opacity ease-in-out 0.25s;
}

.topBanner__content__title {
    color: #000;
    font-weight: 800;
    font-size: 32px;
    padding-bottom: 8px;
    margin-bottom: 16px;
    display: block;
    width: 100%;
    position: relative;
}

.topBanner__content__price {
    color: #000;
    font-family: "IBM Plex Sans",sans-serif;
    font-weight: 600;
    font-size: 23px;
    width: 100%;
    position: relative;
}

.topBanner__content__title:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #000;
}

.topBanner__container .topBanner__content__container__category {
    color: #ff671f;
    font-family: "IBM Plex Sans",sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 8px;
    margin-bottom: 16px;
    display: block;
    width: 100%;
    position: relative;
}

.topBanner__content__text h2 {
    color: #fff;
    font-size: 48px;
    line-height: 48px;
    text-shadow: 0 1px 20px rgb(0 0 0 / 34%);
    font-family: "Bebas Neue",Arial;
    margin: 0;
}

.topBanner__container .topBanner__content__container__category:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #ff671f 0%, rgba(255,103,31,0) 100%);
}

.img-fill{
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
}

.blocks-box,
.slick-slider {
    margin: 0;
    padding: 0;
}

.slick-slider{overflow:hidden;}

.carousel__container .slick-slider .slick-list, .carousel__container .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}

.slick-slider .slick-dots{
    position:absolute;
    height:50px;
    bottom:0px;
    width:100%;
    left:0px;
    padding:0px;
    margin:0px;
    list-style-type:none;
    text-align:center;
}

.slick-slider .slick-dots li{
    display:inline-block;
    width:15px;
    height:15px;
    background:Transparent;
    -webkit-transition:all 0.5s;
    transition:all 0.5s;
    -webkit-border-radius:50%;
    border-radius:50%;
    border:2px solid #FFF;
    margin:0 2.5px;
    cursor:pointer;
    position:relative;
}

.slick-slider .slick-dots li.slick-active{
    background:#fff;
    -webkit-box-shadow:inset 0 0 0 2px rgba(0,0,0,.28);
    box-shadow:inset 0 0 0 2px rgba(0,0,0,.28);
}

.slick-slider .slick-dots li a{
    position:absolute;
    bottom:100%;
    left:50%;
    width:120px;
    height:75px;
    display:block;
    visibility:hidden;
    border:4px solid rgba(0,0,0,.50);
    margin-left:-60px;
    margin-bottom:15px;
    -webkit-transition:visibility 0.5s linear 0.5s;
    transition:visibility 0.5s linear 0.5s;
    -webkit-animation:fadeOut 0.5s both;
    animation:fadeOut 0.5s both;
}

.slick-slider .slick-dots li a:after{
    content:'';
    position:absolute;
    left:50%;
    margin-left:-8px;
    top:100%;
    width:0;
    height:0;
    border:8px solid Transparent;
    border-top-color:rgba(0,0,0,.50);
    margin-top:4px;
}

.slick-slider .slick-dots li:hover a{
    visibility:visible;
    -webkit-transition-delay:0s;
    transition-delay:0s;
    -webkit-animation:fadeIn 0.5s both;
    animation:fadeIn 0.5s both;
}

.slick-slider .slick-dots li a img{
    width:100%;
    height:100%;
}

.slick-slider .item .img-fill{
    height:73vh;
    background:#000;
}

.slick-dots li button {
    display: none;
}

.slick-dots li button:before {
    content: '' !important;
}

.banner-secondary-property .slick-prev {
    left: 3% !important;
    z-index: 1;
}
.banner-secondary-property .slick-next {
    right: 3% !important;
    z-index: 1;
}

.banner-secondary-property .slick-slide {
    padding: 0 5px;
}

.banner-secondary-property .slick-list {
    padding: 0 -5px;
}

.slick-slider.slider-related {
    overflow: visible;
}

@media (max-width: 991px) {
    .topBanner__container {
        height: 100vw;
        min-height: auto;
    }

    .topBanner__container .slick-track>div>div>div {
        transform: none;
    }

    .topBanner__container .slick-track>div .background {
        height: 100vw;
        transition: none;
        -webkit-clip-path: unset;
        clip-path: unset;
    }

    .topBanner__container .slick-track>div .background__inner {
        height: 100vw;
        background-attachment: unset;
    }

    .topBanner__container .slick-track>div .background:after {
        height: 100vw;
    }

    .sportsMatchHighlightHeader_contentContainer {
        margin-top: 16px;
    }

    .sportsMatchHighlightHeader_team img {
        margin-bottom: 0;
    }
}

@media (max-width: 767px) {
    .topBanner__content__text h2 {
        /*margin-bottom: 0;*/
        font-size: 24px;
        line-height: 24px;
    }
    .slick-dots {
        display:none !important;
    }

    .topBanner__content__text {
        top: auto;
        bottom: 0;
        margin-bottom: 5px;
        transform: none;
    }

    .topBanner__content__title {
        color: #000;
        font-family: "Futura",sans-serif;
        font-weight: 600;
        font-size: 18px;
        padding-bottom: 8px;
        margin-bottom: 16px;
        display: block;
        width: 100%;
        position: relative;
    }

    .topBanner__content__price {
        font-size: 14px;
    }
}

@keyframes animate {
    from {
        transform: scale(1) translate(0px);
    }

    to {
        transform: scale(1.1) translate(0px);
    }
}
