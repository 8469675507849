@font-face {
    font-family: "Futura";
    src: local("Futura"),
    url("../../fonts/Futura/FuturaStd-Light.otf") format("truetype");
    font-weight: bold;
}

.section-advise:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            16.71deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 66.73%, rgba(0,0,0,0.13) 100%);
    z-index: 1;
    transform: translate3d(0, 0, 0);
}

.bg-advise {
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    background: rgba(246,246,246,1);
    background-image: url(../../../public/images/eivissa_esvedra.jpg);
    height: 367px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: unset;
    background-attachment: fixed;
}

.advise-title {
    color: #FFF;
    font-family: 'Futura', sans-serif;
    font-weight: 800;
    font-size: 32px;
    display: block;
    width: 100%;
    z-index: 2;
    position: relative;
}

.button-advise {
    z-index: 2;
    font-family: 'Futura', sans-serif;
    font-weight: 800;
    font-size: 22px;
}
